<template>
  <AppPage>
    <section class="bg-background-sagelight">
      <AppPageHeader page-type-label="Procurement" show-account-context backlink-text="Back to Dashboard" :backlink-route="{ name: 'wc-dashboard' }">
        <span v-if="details" class="text-subheading-large-bold text-wrap pt-3.5">
          {{ title }} &mdash; {{ truncateTransactionId(props.transactionId) }}
        </span>
        <Skeleton v-else height="3em" width="10em" />
      </AppPageHeader>
      <div class="wc-page-container grid grid-cols-2 gap-4 pb-16 lg:grid-cols-4">
        <div class="col-span-1">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Purchased Amount</h3>
          </div>
          <div>
            <div v-if="details" class="text-body-1">
              <span data-cy="purchased-amount-number">
                {{ getFormattedEacQuantity(details.totalEacs, EacMeasurementParameter.UnlabeledLong) }}
              </span>
              EACs
            </div>
            <Skeleton v-else height="1.3em" width="50%" />
          </div>
        </div>
        <div class="col-span-1">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Date</h3>
          </div>
          <div>
            <div v-if="details" class="text-body-1" data-cy="payment-time">{{ formatDate(details.payment.createdTime) }}</div>
            <Skeleton v-else height="1.3em" width="50%" />
          </div>
        </div>
        <div class="col-span-1 lg:col-span-2">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Total paid</h3>
          </div>
          <div>
            <span v-if="details" class="text-body-1" data-cy="payment-amount">{{ formatCurrencyInPennies(details.payment.penniesUsd) }}</span>
            <Skeleton v-else height="1.3em" width="50%" />
          </div>
        </div>
      </div>
      <WcTabs>
        <WcTab is-active>Assets</WcTab>
      </WcTabs>
    </section>

    <section class="wc-page-container py-10">
      <template v-if="details">
        <div
          v-for="summary in details.stories"
          :key="summary.story.id"
          class="wc-homepage-grid mb-10 border-neutral-30 pb-6 [&:not(:last-child)]:border-b">
          <div class="col-span-4">
            <router-link v-if="summary.story.id !== 0" :to="{ name: 'wc-story', params: { storyId: summary.story.id } }" target="_blank">
              <h4 class="text-subheading-1">
                {{ summary.story.name }}
              </h4>

              <p class="mt-4">{{ summary.story.shortDescription }}</p>
            </router-link>
            <div v-else>
              <h4 class="text-subheading-1">
                {{ summary.story.name }}
              </h4>
            </div>
          </div>
          <div class="col-span-8 md:-my-2">
            <div class="w-full overflow-x-scroll">
              <WcTable
                class="w-full"
                :data="summary.devices"
                :columns="[
                  { key: 'id', label: 'Asset ID', align: 'left' },
                  { key: 'location', label: 'Location', align: 'left' },
                  { key: 'coordinates', label: 'Coordinates', align: 'left' },
                  { key: 'procuredAmount', label: 'Procured Amount', align: 'left' },
                ]"
                row-id-field="id"
                table-class="text-body-3"
                th-class="text-left py-2"
                td-class="p-3"
                :sortable="false">
                <template #coordinates="{ row: { coordinates } }"> {{ coordinates.latitude }}°, {{ coordinates.longitude }}° </template>
                <template #procuredAmount="{ row: { eacCount } }">
                  {{ getFormattedEacQuantity(eacCount, EacMeasurementParameter.UnlabeledShort) }}
                </template>
              </WcTable>
            </div>
          </div>
        </div>
      </template>
      <Skeleton v-else height="10em" width="100%" />
    </section>
  </AppPage>
</template>

<script setup lang="ts">
// Libraries
import { computed, ref, onMounted } from "vue"
import WcTable from "@/components/WcTable/WcTable.vue"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"
import { setPageTitle } from "@/metatags"
import { PurchaseDetails, StorySummary, truncateTransactionId } from "@/services/api/listing.service"
import formatCurrencyInPenniesFactory from "@/utils/formatCurrencyInPenniesFactory"

// Components
import { AppPage, AppPageHeader, WcTabs, WcTab } from "@/components/layout"
import Skeleton from "primevue/skeleton"

// Models
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"

// Services
import { useListingService } from "@/services/service-container"

const props = defineProps<{
  transactionId: string
}>()

const listingService = useListingService()

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
const formatDate = (date: string) => formatter.format(parseUTCTimestamp(date))
const formatCurrencyInPennies = formatCurrencyInPenniesFactory()

const details = ref<PurchaseDetails | null>(null)
const isLoading = ref(true)

const title = computed(() => {
  if (!details.value || details.value.stories.length === 0) {
    return "Procurement"
  } else if (details.value.stories.length <= 3) {
    return details.value.stories.map((s: StorySummary) => s.story.name).join(", ")
  } else {
    return "Multiple Suppliers"
  }
})

onMounted(async () => {
  try {
    details.value = await listingService.getPurchaseDetails(props.transactionId)
    setPageTitle(`${title.value} — ${truncateTransactionId(props.transactionId)}`)
  } finally {
    isLoading.value = false
  }
})
</script>
