<template>
  <div class="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    <router-link
      v-for="portfolio in portfolios"
      :key="portfolio.id"
      :to="{ name: 'wc-portfolio-tracker', params: { portfolioId: portfolio.id } }"
      class="flex flex-col rounded-lg border border-neutral-30 px-4 pb-4 pt-3">
      <h3 class="text-subheading-1 mb-5 min-w-0 break-words">{{ portfolio.name }} &mdash; {{ portfolio.id }}</h3>
      <div class="flex grow flex-col">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div class="text-subheading-large-bold">
              {{ getFormattedEacQuantity(portfolio.allocations?.allocatedEacs ?? 0, EacMeasurementParameter.UnlabeledShort) }}
            </div>
            <div class="text-body-3">EACs allocated</div>
          </div>
          <div v-if="hasQuantityOrdered(portfolio)">
            <div class="text-subheading-large-bold">
              {{ getFormattedEacQuantity(computeQuantityOrdered(portfolio), EacMeasurementParameter.UnlabeledShort) }}
            </div>
            <div class="text-body-3">EACs ordered</div>
          </div>
        </div>
        <WcProgressBarChart
          v-if="hasQuantityOrdered(portfolio)"
          class="mt-5"
          :percentage="computePercentAllocated(portfolio)"
          label="percentage fulfilled" />
      </div>
      <div class="text-caption mt-6 grid grow-0 grid-cols-2 gap-4 text-hint">
        <div class="capitalize">Status: {{ portfolio.status }}</div>
        <div v-if="computeLatestUpdateTime(portfolio)">Last updated: {{ formatDate(computeLatestUpdateTime(portfolio)) }}</div>
      </div>
    </router-link>
    <router-link
      v-for="summary in purchaseSummaries"
      :key="summary.transactionId"
      :to="{ name: 'wc-procurement-details', params: { transactionId: summary.transactionId } }"
      class="flex flex-col rounded-lg border border-neutral-30 px-4 pb-4 pt-3">
      <h3 class="text-subheading-1 mb-5 min-w-0 break-words">
        {{ formatPurchaseTitle(summary) }} &mdash; {{ truncateTransactionId(summary.transactionId) }}
      </h3>
      <div class="flex grow flex-col">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div class="text-subheading-large-bold">
              {{ getFormattedEacQuantity(summary.totalEacs ?? 0, EacMeasurementParameter.UnlabeledShort) }}
            </div>
            <div class="text-body-3">EACs procured</div>
          </div>
        </div>
      </div>
      <div class="text-caption mt-6 text-hint">Procured on: {{ formatDate(summary.timestamp) }}</div>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { getFormattedEacQuantity, EacMeasurementParameter } from "@/models/order"
import { PortfolioWithSummary } from "@/models/order"
import { PurchaseSummary } from "@/models/listing"
import WcProgressBarChart from "@/components/visualizations/WcProgressBarChart.vue"
import { truncateTransactionId } from "@/services/api/listing.service"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
const formatDate = (date: string) => formatter.format(parseUTCTimestamp(date))

const hasQuantityOrdered = (portfolio: PortfolioWithSummary) => portfolio.relationships?.stakeholder !== null

const computeQuantityOrdered = (portfolio: PortfolioWithSummary) => portfolio.relationships.stakeholder!.quantity

const computePercentAllocated = (portfolio: PortfolioWithSummary) => {
  const allocated = portfolio.allocations?.allocatedEacs ?? 0
  const totalOrdered = computeQuantityOrdered(portfolio)
  if (allocated === 0 || totalOrdered === 0) {
    return 0
  }
  return (allocated / totalOrdered) * 100
}

const computeLatestUpdateTime = (portfolio: PortfolioWithSummary) => {
  const sortedUpdateTimes = [
    portfolio.updatedTime,
    portfolio.allocations.updatedTime,
    portfolio.relationships.stakeholder?.updatedTime,
    portfolio.relationships.supplier?.updatedTime,
  ]
    .filter((time) => time)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
  return sortedUpdateTimes[0]
}

defineProps<{ portfolios: PortfolioWithSummary[]; purchaseSummaries: PurchaseSummary[] }>()

const formatPurchaseTitle = (summary: PurchaseSummary) => {
  if (summary.supplierNames.length == 0) {
    return "Procurement"
  } else if (summary.supplierNames.length <= 3) {
    return summary.supplierNames.join(", ")
  } else {
    return "Multiple suppliers"
  }
}
</script>
