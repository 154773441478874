import { Order } from "@/models/order"
import ApiFetcher from "@/services/api-fetcher"

export default class OrdersService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }
  listOrders = async (): Promise<Array<Order>> => {
    const endpoint = "/orders"
    return await this.fetcher.httpGet<Array<Order>>(endpoint, {})
  }
  listOrdersAdmin = async (): Promise<Array<Order>> => {
    const endpoint = "/orders/admin"
    return await this.fetcher.httpGet<Array<Order>>(endpoint, {})
  }
}
