<template>
  <Icon v-if="isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />

  <table v-else class="w-full">
    <tbody>
      <tr>
        <td colspan="7" class="py-2 font-bold">Supplier</td>
      </tr>
      <tr v-if="suppliers.length > 0" class="border-b border-sagetone">
        <th class="text-left font-normal">Name</th>
        <th class="text-left font-normal">ID</th>
        <th class="text-right font-normal">Date</th>
        <th></th>
        <th class="text-right font-normal">EACs Expected</th>
        <th class="text-right font-normal">EACs Allocated</th>
        <th class="text-right font-normal">Completion</th>
      </tr>
      <tr v-for="portfolio in suppliers" :key="portfolio.id" class="even:bg-highlight-pastelazure">
        <td class="max-w-[20ch] truncate py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-portfolio-suppliers', params: { portfolioId: portfolio.id } }">
            {{ portfolio.name }}
          </router-link>
        </td>
        <td class="text-left">
          {{ portfolio.id }}
        </td>
        <td class="text-right">
          {{ formatDate(portfolio.relationships.supplier!.createdTime) }}
        </td>
        <td></td>
        <td class="text-right">
          {{ portfolio.relationships.supplier!.quantity.toLocaleString() }}
        </td>
        <td class="text-right">
          {{ portfolio.allocations.allocatedEacs.toLocaleString() }}
        </td>
        <td class="text-right">
          <template v-if="portfolio.relationships.supplier!.quantity > 0">
            {{ ((portfolio.allocations.allocatedEacs / portfolio.relationships.supplier!.quantity) * 100).toFixed(2) }}%
          </template>
          <template v-else> &mdash; </template>
        </td>
      </tr>
      <tr v-if="suppliers.length == 0">
        <td colspan="7">Not a supplier for any portfolio</td>
      </tr>
    </tbody>

    <tbody>
      <tr>
        <td colspan="7" class="pb-2 pt-4 font-bold">Stakes</td>
      </tr>
      <tr v-if="stakes.length > 0" class="border-b border-sagetone">
        <th class="text-left font-normal">Name</th>
        <th class="text-left font-normal">ID</th>
        <th class="text-right font-normal">Date</th>
        <th class="text-right font-normal">Paid</th>
        <th class="text-right font-normal">EACs Ordered</th>
        <th class="text-right font-normal">EACs Allocated</th>
        <th class="text-right font-normal">Completion</th>
      </tr>
      <tr v-for="portfolio in stakes" :key="portfolio.id" class="even:bg-highlight-pastelazure">
        <td class="max-w-[20ch] truncate py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-portfolio-stakeholders', params: { portfolioId: portfolio.id } }">
            {{ portfolio.name }}
          </router-link>
        </td>
        <td class="text-left">
          {{ portfolio.id }}
        </td>
        <td class="text-right">
          {{ formatDate(portfolio.relationships.stakeholder!.createdTime) }}
        </td>
        <td class="text-right">
          {{ formatCurrencyInPennies(portfolio.relationships.stakeholder!.paidPenniesUsd ?? 0) }}
        </td>
        <td class="text-right">
          {{ portfolio.relationships.stakeholder!.quantity.toLocaleString() }}
        </td>
        <td class="text-right">
          {{ portfolio.allocations.allocatedEacs.toLocaleString() }}
        </td>
        <td class="text-right">
          <template v-if="portfolio.relationships.stakeholder!.quantity > 0">
            {{ ((portfolio.allocations.allocatedEacs / portfolio.relationships.stakeholder!.quantity) * 100).toFixed(2) }}%
          </template>
          <template v-else> &mdash; </template>
        </td>
      </tr>
      <tr v-if="stakes.length == 0">
        <td colspan="7">Not a stakeholder of any portfolio</td>
      </tr>
    </tbody>

    <tbody>
      <tr>
        <td colspan="7" class="pb-2 pt-4 font-bold">Observers</td>
      </tr>
      <tr v-if="observers.length > 0" class="border-b border-sagetone">
        <th class="text-left font-normal">Name</th>
        <th class="text-left font-normal">ID</th>
        <th class="text-right font-normal">Date</th>
        <th class="text-right font-normal" colspan="4">Referrer</th>
      </tr>
      <tr v-for="portfolio in observers" :key="portfolio.id" class="even:bg-highlight-pastelazure">
        <td class="max-w-[20ch] truncate py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-portfolio-observers', params: { portfolioId: portfolio.id } }">
            {{ portfolio.name }}
          </router-link>
        </td>
        <td class="text-left">
          {{ portfolio.id }}
        </td>
        <td class="text-right">
          {{ formatDate(portfolio.relationships.observer!.createdTime) }}
        </td>
        <td class="text-right underline" colspan="4">
          <router-link :to="{ name: 'wc-admin-account-portfolios', params: { accountId: portfolio.relationships.observer.referrer.id } }">
            {{ portfolio.relationships.observer.referrer.name }}
          </router-link>
        </td>
      </tr>
      <tr v-if="observers.length == 0">
        <td colspan="7">Not an observer of any portfolio</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { PortfolioWithSummary } from "@/models/order"
import { usePortfolioService } from "@/services/service-container"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"
import formatCurrencyInPenniesFactory from "@/utils/formatCurrencyInPenniesFactory"

const props = defineProps<{
  accountId: number
}>()

const portfolioService = usePortfolioService()

const isLoading = ref<boolean>(true)
const portfolios = ref<PortfolioWithSummary[]>([])

const formatCurrencyInPennies = formatCurrencyInPenniesFactory()
const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
const formatDate = (date: string) => formatter.format(parseUTCTimestamp(date))

const suppliers = computed(() => portfolios.value.filter((p) => p.relationships.supplier !== null))
const observers = computed(() => portfolios.value.filter((p) => p.relationships.observer !== null))
const stakes = computed(() => portfolios.value.filter((p) => p.relationships.stakeholder !== null))

watch(
  () => props.accountId,
  async (accountId) => {
    isLoading.value = true
    try {
      portfolios.value = await portfolioService.listPortfolios({ accountId })
    } finally {
      isLoading.value = false
    }
  },
  { immediate: true }
)
</script>
